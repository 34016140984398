import React, { memo } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  setShowMembershipModal,
  setMembershipSidebarProps
} from '../../redux/designerReducers/designerReducer'
import { gotoLogIn } from '../../redux/actions/AuthActions'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

import MyRoomBg from '../../assets/decorate-my-room/modal1.png'
import MyDecorBg from '../../assets/decorate-my-room/modal2.png'
import RemoveBg from '../../assets/decorate-my-room/modal3.png'
import ARBg from '../../assets/decorate-my-room/modal4.png'
import DBg from '../../assets/decorate-my-room/modal5.png'
import FurnitureBg from '../../assets/decorate-my-room/furniture-modal.png'
import MyDecorOrganizerBg from '../../assets/decorate-my-room/mydecororganizer-modal.png'
import BadgeBg from '../../assets/decorate-my-room/badge-modal.png'
import WishlistBg from '../../assets/decorate-my-room/wishlist-modal.png'
import SearchBg from '../../assets/decorate-my-room/search-modal.png'
import Filter1 from '../../assets/decorate-my-room/filter1.gif'
import Filter2 from '../../assets/decorate-my-room/filter2.gif'
import Templates from '../../assets/decorate-my-room/unlimited-templates.png'
import ArViewImg from '../../assets/decorate-my-room/arViewImage.png'
import UnlimitedDraftsImg from '../../assets/decorate-my-room/PrivilegeDraft.png'
import UneditableItemsImg from '../../assets/decorate-my-room/uneditableItemsImage.png'
import membershipIcon from '../../assets/decorate-my-room/membership.svg'

import useModal from '../../hooks/useModal'

import ModalOverlay from '../common/ModalOverlay'
import { logEvent } from '../../utils/firebase'
import { usePayment } from '../MembershipModal/context/payment'

SwiperCore.use([Pagination])

export const GlobalStyle = createGlobalStyle`
  .swiper-pagination-bullet {
    width: 12px!important;
    height: 12px!important;
    background: #C4C4C4!important;
    opacity: 1!important;
    
    @media only screen and (max-width: 740px) {
      width: 8px!important;
      height: 8px!important;
      margin: 0 3px!important;
    }
  }

  .swiper-pagination-bullet-active {
    background: #FF5E6D!important;
  }
`

const Modal = styled.div`
  position: absolute;
  z-index: 1000;
  position: relative;
  width: min(720px, 95vw);
  height: min(800px, 95vh);
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;

  & > div {
    flex-grow: 1;
    width: 100%;
  }

  @media only screen and (max-width: 740px) {
    height: min(800px, 85vh);
    align-self: flex-start;
    margin-top: 2vw;
  }
`

const Container = styled.div`
  font-family: 'Helvetica Neue';
  font-style: normal;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-size: min(500px, 70vh);
  background-position: center 70%;
  background-repeat: no-repeat;
  height: 100%;
  cursor: grab;
  user-select: none;

  @media only screen and (max-width: 1020px) {
    width: min(720px, 95vw);
    margin: auto;
    background-size: 95%;
  }

  @media only screen and (max-width: 600px) {
    background-position: center 90%;
  }
`

const Close = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  background: none;
  margin: 16px;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  z-index: 10;
  @media only screen and (max-width: 740px) {
    margin: 10px;
  }

  &:before,
  &:after {
    border-radius: 3px;
    height: 3px;
    width: 40px;
    background: #8b8b8b;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);

    @media only screen and (max-width: 740px) {
      height: 2px;
      width: 25px;
    }
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
const Title = styled.span`
  margin: 66px 0 25px 0;
  font-weight: 700;
  font-size: 32px;

  @media only screen and (max-width: 1020px) {
    font-size: 24px;
    margin: 50px 0 20px;
  }

  @media only screen and (max-width: 740px) {
    font-size: 18px;
    margin: 45px 0 10px;
  }

  & span {
    color: #ff5e6d;
  }
`

const Alert = styled.span`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  font-size: 18px;
  font-weight: 400;
  color: #1d6d75;

  @media only screen and (max-width: 740px) {
    font-size: 12px;
    top: 15px;
  }
`
const Subtitle = styled.span`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: auto;

  @media only screen and (max-width: 1020px) {
    font-size: 16px;
    line-height: 19px;
    max-width: 470px;
  }

  @media only screen and (max-width: 740px) {
    font-size: 14px;
    line-height: 16.7px;
    max-width: 300px;
    margin: 0 auto auto;
  }
`

const Button = styled.button`
  font-family: Helvetica Neue;
  background-color: #ff5e6d;
  width: 280px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 50px;
  margin-top: 10px;
  border: none;
  color: #ffffff;
  cursor: pointer;

  @media only screen and (max-width: 1020px) {
    margin-bottom: 12px;
    height: 48px;
    width: 240px;
    border-radius: 24px;
  }
`

const ImageContainer = styled.img`
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: calc(100% - 50px);
  max-width: 500px;
  object-fit: contain;
  margin: 40px 25px 55px;

  @media only screen and (max-width: 740px) {
    margin: 20px 25px 35px;
  }
`

const FilterContainer = styled.div`
  padding-top: 70%;
  width: 70%;
  position: absolute;
  top: 130px;

  @media only screen and (max-width: 740px) {
    top: 50%;
    transform: translateY(-40%);
  }

  &:after,
  &:before {
    top: 5%;
    content: '';
    display: block;
    background: linear-gradient(180deg, #ffdee1 0%, #ffeeeb 100%);
    width: 75%;
    padding-top: 75%;
    border-radius: 100%;
    position: absolute;
    z-index: 0;
  }

  &:after {
    width: 40%;
    padding-top: 40%;
    bottom: 5%;
    top: auto;
    right: 0;
  }

  & img {
    filter: drop-shadow(0px 2px 6px rgba(255, 94, 109, 0.2));
    border-radius: 16px;
    position: absolute;
    top: 10%;
    left: 7%;
    width: 60%;
    z-index: 1;

    &:last-child {
      width: 40%;
      top: auto;
      left: auto;
      right: 10%;
      bottom: 5%;
    }
  }
`

const FeatureLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-bottom: 80px;

  @media (max-width: 800px) {
    width: 80%;
    height: 100%;
    padding-top: 20px;
    overflow-y: scroll;
  }
`

const FeatureLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  & img {
    width: 20px;
  }

  @media (max-width: 800px) {
    gap: 8px;
    font-size: 12px;

    & img {
      width: 12px;
      height: 12px;
    }
  }
`

export const DownLine = styled.span`
  display: none;
  color: #7c7c7c;
  text-align: center;
  font-size: 12px;
  margin-bottom: 22px;

  @media (max-width: 800px) {
    display: block;
  }
`

const contentMap = [
  {
    title: 'Access ',
    highlight: 'MyDecor Organizer',
    subtitle:
      'Organize personal MyDecor items into categories to better manage them.',
    backgroundImage: MyDecorOrganizerBg,
    type: 'organizer'
  },
  {
    title: 'Unlimited ',
    highlight: 'Usage of All Furniture',
    subtitle:
      'Enjoy designing without any limits to furniture and decor usage.',
    backgroundImage: FurnitureBg,
    type: 'furniture'
  },
  {
    title: 'Unlimited ',
    highlight: 'MyRoom Storage',
    subtitle: 'Design and decorate real spaces by uploading personal photos.',
    backgroundImage: MyRoomBg,
    type: 'room'
  },
  {
    title: 'Unlimited ',
    highlight: 'MyDecor Storage',
    subtitle: 'Design and decorate real spaces by uploading personal photos.',
    backgroundImage: MyDecorBg,
    type: 'decor'
  },
  {
    title: 'Unlimited ',
    highlight: 'MyDecor Templates',
    subtitle: 'Use our premium templates to design.',
    backgroundImage: Templates,
    type: 'templates'
  },
  {
    title: 'Access ',
    highlight: 'Unlimited Drafts',
    subtitle: 'Save as many design drafts as you need.',
    backgroundImage: UnlimitedDraftsImg,
    type: 'designDrafts'
  },
  {
    title: 'Access ',
    highlight: 'Animated Filters',
    subtitle: 'Make your design stand out by adding a special animated filter.',
    backgroundImage: Templates,
    type: 'filter'
  },
  {
    title: 'Unlock ',
    highlight: 'Uneditable Items',
    subtitle: 'Gain full customization over added items on templates',
    backgroundImage: UneditableItemsImg,
    type: 'uneditable'
  },
  {
    title: 'Access ',
    highlight: 'Item Search',
    subtitle: 'Use the Item Search to find furnitures quickly.',
    backgroundImage: SearchBg,
    type: 'search'
  },
  {
    title: 'Access ',
    highlight: 'Remove Item Background',
    subtitle:
      'Get access to the AI tool to remove items background when uploading personal furnitures.',
    backgroundImage: RemoveBg,
    type: 'background'
  },
  {
    title: 'Access ',
    highlight: 'AR Ruler',
    subtitle: 'Measure your room anytime when you decorate',
    alert: '(iOS App Only)',
    backgroundImage: ARBg,
    type: 'ruler'
  },
  {
    title: 'Access ',
    highlight: 'AR View',
    subtitle: 'View the room using our AR technology',
    alert: '(iOS App Only)',
    backgroundImage: ArViewImg,
    type: 'ar'
  },
  {
    title: 'Access ',
    highlight: '3D View',
    subtitle: 'Measure your room anytime when you decorate',
    alert: '(Developing)',
    backgroundImage: DBg,
    type: '3d'
  },
  {
    title: 'Access ',
    highlight: 'Wishlist',
    subtitle: 'Save your favorite furnitures for easy access.',
    backgroundImage: WishlistBg,
    type: 'wishlist'
  },
  {
    title: 'Earn ',
    highlight: 'Special Badge',
    subtitle:
      'Earn a unique membership badge that will display at the front of your profile.',
    backgroundImage: BadgeBg,
    type: 'badge'
  },
  {
    type: 'featureList',
    list: [
      'Unlimited Usage of All Furniture',
      'Unlimited Uploads of MyRoom',
      'Unlimited Uploads of MyDecor Items',
      'Unlimited Space for Design Drafts',
      'Design with Amazing Animated Filters',
      'Access Unlimited Templates',
      'Access Background Eraser',
      'Access AR View',
      'Access AR Ruler',
      'Access Item Search',
      'Access Wishlist',
      'Unlock Uneditable Items',
      'Earn Special Badge',
      'Access 3D Models (coming soon)'
    ]
  }
]
const FeatureModal = memo(({ data, setData }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  useModal(!!data)

  const { membershipPackages, selectPackage } = usePayment() || {}

  const handleClick = e => {
    if (!isAuthenticated) {
      dispatch(gotoLogIn())
      return
    }

    logEvent('show_purchase_coin', {
      content_type: 'purchase'
    })
    if (membershipPackages)
      selectPackage(membershipPackages.find(item => item.name === 'Weekly'))
    dispatch(setShowMembershipModal(true))
    dispatch(setMembershipSidebarProps({ relatedFeature: data }))
    setData(null)
  }

  const handleClose = () => {
    setData(null)
  }

  if (!data) return null

  const initialSlide = contentMap.findIndex(({ type }) => type === data)

  return (
    <ModalOverlay fullscreen>
      <Modal>
        <Close title="Close" onClick={handleClose} />
        <Swiper
          pagination={{ clickable: true }}
          initialSlide={initialSlide}
          loop
        >
          {contentMap.map(feature => {
            return (
              <SwiperSlide key={feature.type}>
                {feature.type === 'featureList' && (
                  <Container>
                    <Title>
                      Member <span>Exclusive Privileges</span>
                    </Title>
                    <FeatureLines>
                      {feature.list.map((item, index) => (
                        <FeatureLine key={index}>
                          <img src={membershipIcon} alt="" />
                          <span>{item}</span>
                        </FeatureLine>
                      ))}
                    </FeatureLines>
                  </Container>
                )}
                {feature.type !== 'featureList' && (
                  <Container>
                    {feature.alert && <Alert>{feature.alert}</Alert>}
                    <Title>
                      {feature.title}
                      <span>{feature.highlight}</span>
                    </Title>
                    <Subtitle>{feature.subtitle}</Subtitle>
                    {feature.type === 'filter' ? (
                      <FilterContainer>
                        <img alt="" src={Filter1} />
                        <img alt="" src={Filter2} />
                      </FilterContainer>
                    ) : (
                      <ImageContainer
                        alt=""
                        src={feature.backgroundImage}
                        loading="lazy"
                      />
                    )}
                  </Container>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Button onClick={handleClick}>
          {isAuthenticated ? 'Join Membership' : 'Free Sign Up'}
        </Button>
        <DownLine>Membership as low as $9.99</DownLine>
      </Modal>
      <GlobalStyle />
    </ModalOverlay>
  )
})

export default FeatureModal
